import { SupportedNetworks } from './networks'

type graphEndPointType = {
  [key in SupportedNetworks]: string
}

const isPublic = process.env.REACT_APP_PUBLIC === 'true'
const MAINNET_KEY =
  process.env.REACT_APP_MAINNET_URI ?? 'https://eth-mainnet.alchemyapi.io/v2/vI8OBZj4Wue9yNPSDVa7Klqt-UeRywrx'

export const networkToProvider: { [key in SupportedNetworks]: string } = {
  [SupportedNetworks.Mainnet]: MAINNET_KEY,
  [SupportedNetworks.Goerli]: MAINNET_KEY,
  [SupportedNetworks.Avalanche]: 'https://api.avax.network/ext/bc/C/rpc',
  [SupportedNetworks.Arbitrum]: 'https://arb1.arbitrum.io/rpc',
  [SupportedNetworks.Matic]: 'https://rpc-mainnet.maticvigil.com/',
}

export const networkIdToName: { [key in SupportedNetworks]: string } = {
  [SupportedNetworks.Mainnet]: `Mainnet`,
  [SupportedNetworks.Goerli]: `Goerli`,
  [SupportedNetworks.Avalanche]: 'Avalanche',
  [SupportedNetworks.Arbitrum]: 'Arbitrum',
  [SupportedNetworks.Matic]: 'Polygon',
}

export const subgraph: graphEndPointType = {
  [SupportedNetworks.Mainnet]: isPublic
    ? 'https://api.goldsky.com/api/public/project_clch40o0v0d510huoey7g5yaz/subgraphs/gamma-mainnet/prod/gn'
    : 'https://api.thegraph.com/subgraphs/name/opynfinance/playground',
  [SupportedNetworks.Goerli]: 'https://api.thegraph.com/subgraphs/name/opynfinance/gamma-goerli',
  [SupportedNetworks.Avalanche]: 'https://api.goldsky.com/api/public/project_clch40o0v0d510huoey7g5yaz/subgraphs/gamma-avax/prod/gn',
  [SupportedNetworks.Arbitrum]: 'https://api.thegraph.com/subgraphs/name/opynfinance/gamma-arbitrum-one',
  [SupportedNetworks.Matic]: 'https://api.thegraph.com/subgraphs/name/opynfinance/gamma-matic',
}

export const ZeroXEndpoint: { [key in SupportedNetworks]: { http: string; ws: string } } = {
  [SupportedNetworks.Mainnet]: {
    http: 'https://api.0x.org/',
    ws: 'wss://api.0x.org/orderbook/v1',
  },
  [SupportedNetworks.Goerli]: {
    http: '',
    ws: 'wss://api.0x.org/orderbook/v1', // prevent useWebsocket error
  },
  [SupportedNetworks.Arbitrum]: {
    http: '',
    ws: 'wss://api.0x.org/orderbook/v1', // prevent useWebsocket error
  },
  [SupportedNetworks.Avalanche]: {
    http: 'https://avalanche.api.0x.org/',
    ws: 'wss://api.0x.org/orderbook/v1', // prevent useWebsocket error
  },
  [SupportedNetworks.Matic]: {
    http: '',
    ws: 'wss://api.0x.org/orderbook/v1', // prevent useWebsocket error
  },
}
